var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass:
        "option-button d-flex flex-row justify-content-between align-items-center",
      attrs: { "no-body": "" },
    },
    [
      _c("div", [_vm._v("\n    " + _vm._s(_vm.option.text) + "\n  ")]),
      _c("fa-icon", {
        staticClass: "float-right",
        attrs: { icon: "chevron-right" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }