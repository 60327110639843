<template>
  <b-card no-body class="option-button d-flex flex-row justify-content-between align-items-center">
    <div>
      {{ option.text }}
    </div>
    <fa-icon icon="chevron-right" class="float-right" />
  </b-card>
</template>

<script>
export default {
  name: 'OptionButton',
  props: {
    option: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.option-button {
  @include ct-ui-card-shadow;
  @include ct-ui-selectable-horizontal;
  padding: 1rem !important;
}
</style>
